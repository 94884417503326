import Formsy from 'formsy-react'
import React, { Component } from 'react'
// noinspection ES6CheckImport
import PropTypes from 'prop-types'
import Input from '../form/input/input'
import FormTextarea from '../form/textarea/textarea'
import FileUpload from '../form/file-upload/file-upload'
import Button from '../button/button'

const FILE_SIZE_LIMIT = 10 * 1024 * 1024
const FORM_HANDLER_URL =
    'https://getform.io/f/87da38f2-3e46-47b4-9fa1-af5f0fc01399'

function encode(data) {
    const formData = new FormData()

    Object.keys(data).forEach(key => {
        formData.append(key, data[key])
    })

    return formData
}

function fileOnClick(event) {
    // eslint-disable-next-line
    event.target.value = null
}

function mapServiceNames(inputs) {
    const res = { ...inputs, services: [] }
    const serviceDef = [
        ['services_create_new_brand_website', 'Create a new brand or website'],
        [
            'services_validate_test_product_idea',
            'Validate and test a product idea',
        ],
        ['services_design_build_product', 'Design and build a product'],
        ['services_improve_product', 'Improve an existing product'],
    ]

    serviceDef.forEach(service => {
        if (inputs[service[0]]) {
            res.services.push(service[1])
        }
        delete res[service[0]]
    })

    return res
}

export default class ContactForm extends Component {
    constructor(props) {
        super(props)

        this.disableButton = this.disableButton.bind(this)
        this.enableButton = this.enableButton.bind(this)
        this.handleAttachment = this.handleAttachment.bind(this)
        this.detachFile = this.detachFile.bind(this)
        this.sendForm = this.sendForm.bind(this)

        this.state = {
            canSubmit: true,
            isSending: false,
            selectedFile: null,
            fileError: '',
            isAboutFieldError: false,
        }
    }

    setAboutFieldError(isError) {
        this.setState({ isAboutFieldError: isError })
    }

    disableButton() {
        this.setState({ canSubmit: false })
    }

    enableButton() {
        this.setState({ canSubmit: true })
    }

    handleAttachment(e) {
        const file = e.target.files[0]

        if (file.size > FILE_SIZE_LIMIT) {
            this.setState({
                selectedFile: null,
                fileError:
                    "Couldn't process the attachment. Try again with a file under 10MB",
            })
        } else {
            this.setState({ selectedFile: file, fileError: '' })
        }
    }

    detachFile() {
        this.setState({ selectedFile: null })
    }

    sendForm(formData) {
        const { selectedFile } = this.state
        const { onSuccessfulSubmit } = this.props
        const formDataWithBrief = { brief: selectedFile, ...formData }

        const body = encode(formDataWithBrief)

        this.setState({ isSending: true })

        //        console.log(formDataWithBrief)
        //         Promise.resolve(body)
        fetch(FORM_HANDLER_URL, {
            method: 'POST',
            headers: { Accept: 'application/json' },
            body,
        })
            .then(() => {
                this.setState({ isSending: false })
                onSuccessfulSubmit()
            })
            .catch(error => {
                this.setState({ isSending: false })
                console.error(error)
            })
    }

    render() {
        const {
            selectedFile,
            fileError,
            canSubmit,
            isAboutFieldError,
            isSending,
        } = this.state

        return (
            <div className="form-wrapper">
                <div className="heading-container heading-container--form">
                    <h1 className="h2">We&apos;d love to chat</h1>
                    <p className="p-l">
                        Fill in the form, or if you prefer email us:{' '}
                        <strong className="nobr">
                            <a href="mailto:hello@southlane.com">
                                hello@southlane.com
                            </a>
                        </strong>
                    </p>
                </div>

                <Formsy
                    name="contact"
                    method="post"
                    onValidSubmit={this.sendForm}
                    mapping={mapServiceNames}
                    noValidate
                >
                    <div className="form-fieldset form-fieldset--type-special">
                        <Input
                            type="text"
                            name="name"
                            label="Your first name"
                            required
                            validationErrors={{
                                isDefaultRequiredValue:
                                    "We'd love to be on a first name basis.",
                            }}
                        />
                        <Input
                            type="email"
                            name="email"
                            label="Your email address"
                            required
                            validateOnBlur
                            validations={{
                                isEmail: true,
                            }}
                            validationErrors={{
                                isEmail:
                                    "Looks like something isn't right here",
                                isDefaultRequiredValue:
                                    "Looks like something isn't right here",
                            }}
                        />
                    </div>

                    <div className="form-fieldset">
                        <h2 className="h5">How can we best help you?</h2>

                        <Input
                            type="checkbox"
                            name="services_create_new_brand_website"
                            label="Create a new brand or website"
                        />
                        <Input
                            type="checkbox"
                            name="services_validate_test_product_idea"
                            label="Validate and test a product idea"
                        />
                        <Input
                            type="checkbox"
                            name="services_design_build_new_product"
                            label="Design and build a product"
                        />
                        <Input
                            type="checkbox"
                            name="services_improve_product"
                            label="Improve an existing product"
                        />
                    </div>

                    <div className="form-fieldset">
                        <div
                            className={`form-group ${
                                isAboutFieldError ? 'form-group--invalid' : ''
                            }`}
                        >
                            <FormTextarea
                                name="projectDescription"
                                label="Tell us about your project"
                                required
                                onValid={() => this.setAboutFieldError(false)}
                                onInvalid={() => this.setAboutFieldError(true)}
                                validationErrors={{
                                    isDefaultRequiredValue: "Don't be shy.",
                                }}
                            />
                            <FileUpload
                                name="brief"
                                onChange={this.handleAttachment}
                                onClick={fileOnClick}
                            />
                        </div>

                        {selectedFile && (
                            <div className="form-attachment-info">
                                <span className="form-attachment-info__name">
                                    <i className="ico ico--check ico--success" />
                                    {selectedFile.name}
                                </span>
                                <span
                                    role="button"
                                    tabIndex="0"
                                    className="form-attachment-info__detach"
                                    onKeyUp={this.detachFile}
                                    onClick={this.detachFile}
                                >
                                    <i className="ico ico--close" />
                                </span>
                            </div>
                        )}

                        {fileError && (
                            <div className="form-attachment-info">
                                <div className="form-control__error">
                                    {fileError}
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="form-submit">
                        <Button
                            type="submit"
                            text="Send"
                            className={`button--l-mobile ${
                                isSending ? 'button--loading' : ''
                            }`}
                            disabled={!canSubmit}
                        />
                    </div>
                </Formsy>
            </div>
        )
    }
}

ContactForm.propTypes = {
    onSuccessfulSubmit: PropTypes.func.isRequired,
}
