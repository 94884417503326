import React from 'react'
import '../../assets/scss/success-error.scss'
import Button from '../button/button'

const Content = () => {
    return (
        <div className="form-wrapper">
            <div className="success-error">
                <div className="success-error__icon-container">
                    <i className="ico ico--check ico--check-xl ico--success" />
                </div>

                <div className="form-wrapper__heading success-error__heading">
                    Thanks for reaching out!
                </div>

                <p className="success-error__message p-l">
                    We&apos;ll take a look through the info that you&apos;ve
                    shared, do a bit of our own research, and if it looks like
                    we&apos;re a good fit for each other we&apos;ll get back to
                    you as soon as possible.
                </p>

                <Button
                    text="Keep looking around"
                    href="/"
                    className="button--l-mobile"
                />
            </div>
        </div>
    )
}

export default Content
