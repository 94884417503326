// noinspection ES6CheckImport
import PropTypes from 'prop-types'
import { withFormsy } from 'formsy-react'
import React from 'react'

class MyInput extends React.Component {
    constructor(props) {
        super(props)

        this.changeValue = this.changeValue.bind(this)
        this.validateValue = this.validateValue.bind(this)
        this.blurTriggered = false
    }

    changeValue(event) {
        const { setValue, type } = this.props

        const value =
            type === 'checkbox'
                ? event.currentTarget.checked || ''
                : event.currentTarget.value

        // setValue() will set the value of the component, which in
        // turn will validate it and the rest of the form
        // Important: Don't skip this step. This pattern is required
        // for Formsy to work.
        setValue(value)
    }

    validateValue(event) {
        const value = event.currentTarget.value

        if (!value) {
            return
        }

        const { setValue } = this.props

        this.blurTriggered = true

        setValue(value)
    }

    render() {
        const {
            type,
            name,
            label,
            value,
            errorMessage,
            isPristine,
            isFormSubmitted,
            isValid,
            className,
            validateOnBlur,
        } = this.props

        // An error message is returned only if the component is invalid
        const showError =
            !isPristine &&
            !isValid &&
            (isFormSubmitted ||
                !validateOnBlur ||
                (validateOnBlur && this.blurTriggered))

        let classNameInner = 'form-control form-control--input'
        classNameInner += ` form-control--${type} `
        classNameInner += showError ? ' form-control--invalid ' : ''
        classNameInner += ` ${className} `

        return (
            <label className={classNameInner}>
                <input
                    type={type}
                    name={name}
                    placeholder={label}
                    value={value || ''}
                    checked={type === 'checkbox' && value ? 'checked' : false}
                    onBlur={this.validateValue}
                    onChange={this.changeValue}
                />
                <i />
                <div className="form-control__label">{label}</div>
                {showError && (
                    <div className="form-control__error">{errorMessage}</div>
                )}
            </label>
        )
    }
}

export default withFormsy(MyInput)

MyInput.propTypes = {
    name: PropTypes.string,
    type: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    value: PropTypes.any,
    setValue: PropTypes.func,
    errorMessage: PropTypes.string,
    isPristine: PropTypes.bool,
    isFormSubmitted: PropTypes.bool,
    isValid: PropTypes.bool,
    className: PropTypes.string,
    validateOnBlur: PropTypes.bool,
}

MyInput.defaultProps = {
    name: '',
    value: null,
    setValue: null,
    errorMessage: null,
    isPristine: null,
    isValid: null,
    isFormSubmitted: null,
    className: '',
    validateOnBlur: false,
}
