// noinspection ES6CheckImport
import PropTypes from 'prop-types'
import React from 'react'
import { withFormsy } from 'formsy-react'
import Textarea from 'react-expanding-textarea'
import './textarea.scss'

let isError = false

const FormTextarea = ({
    name,
    label,
    className,
    setValue,
    errorMessage,
    isPristine,
    isValid,
    onValid,
    onInvalid,
}) => {
    const changeValue = event => {
        const value = event.currentTarget.value

        if (!value) {
            return
        }

        setValue(value)
    }

    // An error message is returned only if the component is invalid
    const showError = !isPristine && !isValid

    if (!isError && showError) {
        // This is a hack to prevent React complaining about update happening during render.
        // Not sure how else to trigger update in parent (form) element!
        setTimeout(onInvalid, 0)
        isError = true
    }

    if (isError && !showError) {
        setTimeout(onValid, 0)
        isError = false
    }

    let classNameInner = 'form-control form-control--textarea'
    classNameInner += showError ? ' form-control--invalid ' : ''
    classNameInner += ` ${className} `

    return (
        <label className={classNameInner}>
            <Textarea
                maxLength="3000"
                name={name}
                placeholder={label}
                onBlur={changeValue}
                onChange={changeValue}
            />
            <div className="form-control__label">{label}</div>
            {showError && (
                <div className="form-control__error">{errorMessage}</div>
            )}
        </label>
    )
}

export default withFormsy(FormTextarea)

FormTextarea.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    className: PropTypes.string,
    setValue: PropTypes.func,
    errorMessage: PropTypes.string,
    isPristine: PropTypes.bool,
    isValid: PropTypes.bool,
    onValid: PropTypes.func,
    onInvalid: PropTypes.func,
}

FormTextarea.defaultProps = {
    name: '',
    label: '',
    className: '',
    setValue: null,
    errorMessage: null,
    isPristine: null,
    isValid: null,
    onValid: null,
    onInvalid: null,
}
