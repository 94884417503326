// noinspection ES6CheckImport
import PropTypes from 'prop-types'
import React from 'react'
import './file-upload.scss'

const FileUpload = ({ name, required, className, onChange, onClick }) => {
    return (
        <label className={`form-control form-control--file ${className || ''}`}>
            <input
                type="file"
                name={name}
                required={required}
                onChange={onChange}
                onClick={onClick}
            />
            Attach a file
        </label>
    )
}

export default FileUpload

FileUpload.propTypes = {
    name: PropTypes.string,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    className: PropTypes.string,
}

FileUpload.defaultProps = {
    name: '',
    required: false,
    onChange: null,
    onClick: null,
    className: '',
}
