import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Layout from '../components/layout'
import Form from '../components/contact/form'
import Success from '../components/contact/success'
import Seo from '../components/seo/seo'

const Content = ({ location }) => {
    const [isSubmitted, setIsSubmitted] = useState(false)

    const onSubmit = () => {
        setIsSubmitted(true)
        window.scrollTo(0, 0)
    }

    return (
        <Layout isContacts>
            <Seo
                title="Contact - South Lane"
                desc="We'd love to chat about your next project. Simply fill in the form on our contact page, or if your prefer send us an email."
                pathname={location.pathname}
            />
            {!isSubmitted && <Form onSuccessfulSubmit={() => onSubmit()} />}
            {isSubmitted && <Success />}
        </Layout>
    )
}

Content.propTypes = {
    location: PropTypes.shape({ pathname: PropTypes.string.isRequired })
        .isRequired,
}

export default Content
